import Office1 from '../../Media/Kensington/Office/Office (1).webp'
import Office2 from '../../Media/Kensington/Office/Office (2).webp'
import Office3 from '../../Media/Kensington/Office/Office (3).webp'
import Office4 from '../../Media/Kensington/Office/Office (4).webp'
import Office5 from '../../Media/Kensington/Office/Office (5).webp'
import Office6 from '../../Media/Kensington/Office/Office (6).webp'
import Office7 from '../../Media/Kensington/Office/Office (7).webp'
import Office8 from '../../Media/Kensington/Office/Office (8).webp'

const Officeimages =[
Office1, Office2, Office3, Office4, Office5, Office6, Office7, Office8
]

export default Officeimages;