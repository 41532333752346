import React from "react";
import "./produk.scss";
import Apartimg from "../../Media/Kensington/Produk 2.webp";
import Officeimg from "../../Media/Kensington/Produk 1.webp";
import masterimg from "../../Media/Kensington/Kensington_Masteplan.webp";

const produk = () => {
  const Residentialwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Residential)%20https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const officewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Office)%20https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="produk" className="produk-kensington">
      <div className="container">
        <hr />

        <div className="container-judul">
          A Harmonious Fusion of Contemporary Living & Natural Beauty
        </div>

        <div className="container-produk">
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Apartimg} alt={Apartimg} />
            </div>
            <div className="judulproduk">Residential Tower</div>
            <div className="judulproduk2">The Art of Fine Living</div>
            <div className="deskproduk1">
              Kensington Royal Suites hadir sebagai apartemen premium yang
              memadukan kesempurnaan dan kemewahan dengan sentuhan alam yang
              menenangkan. Dengan Terrace Garden, Podium Garden, dan Swimming
              Pool yang menghadirkan lanskap hijau yang luas, setiap sudutnya
              dirancang untuk menciptakan oase baru bagi kenyamanan dan
              kebahagiaan Anda.
            </div>
            <a href="#apartemen">Learn More</a>
            <button className="buttonpenawaran" onClick={Residentialwa}>
              Whatsapp
            </button>
          </div>
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Officeimg} alt={Officeimg} />
            </div>
            <div className="judulproduk">Office Tower</div>
            <div className="judulproduk2">Elevate Your Office Experience</div>
            <div className="deskproduk2">
              Mengusung desain arsitektur modern, The Kensington Office Tower
              menawarkan gaya hidup dinamis dengan akses mudah ke pusat bisnis
              di Kelapa Gading. Untuk memaksimalkan produktivitas, gedung ini
              menyediakan ruang kantor terbuka yang luas, dilengkapi dengan
              taman dengan konsep inovatif yang menyegarkan dan memotivasi.
            </div>
            <a href="#office">Learn More</a>
            <button className="buttonpenawaran" onClick={officewa}>
              Whatsapp
            </button>
          </div>
        </div>
        <hr />
        <div className="masterpalanimg">
          <img src={masterimg} alt={masterimg} />
        </div>
      </div>
    </div>
  );
};

export default produk;
