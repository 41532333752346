import React from "react";
import "./footer.scss";
import logo from "../../Media/logo_ken.png";
import {Link} from "react-router-dom";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>The Kensington</h2>
            <h3>
              Jl. Boulevard Raya No.1, RT.1/RW.2, Klp. Gading Tim., Kec. Klp.
              Gading, Jkt Utara, Daerah Khusus Ibukota Jakarta 14240
            </h3>
            <h4>Contact us : +6281281841001</h4>
            <Link to="/privacy-policy">
              <div className="footer-contact">Privacy Policy</div>
            </Link>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <hr />
      <div className="container-bawah">© The Kensington</div>
    </div>
  );
};

export default footer;
