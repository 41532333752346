import React, {useState} from "react";
import "./navbar.scss";
import Logo from "../../Media/logo_ken.png";
import {HashLink} from "react-router-hash-link";
import {FaBars} from "react-icons/fa";
import {RiCloseLine} from "react-icons/ri";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="navbar">
      <div className="container-navbar">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className={isOpen ? "menus active" : "menus"}>
          <ul>
            <li>
              <HashLink
                className={`link ${activeLink === "#about" ? "active" : ""}`}
                smooth
                to="#about"
                onClick={() => handleLinkClick("#about")}>
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink
                className={`link ${activeLink === "#promo" ? "active" : ""}`}
                smooth
                to="#promo"
                onClick={() => handleLinkClick("#promo")}>
                Promo
              </HashLink>
            </li>
            <li>
              <HashLink
                className={`link ${activeLink === "#rumah" ? "active" : ""}`}
                smooth
                to="#apartemen"
                onClick={() => handleLinkClick("#rumah")}>
                Residentials
              </HashLink>
            </li>
            <li>
              <HashLink
                className={`link ${
                  activeLink === "#apartemen" ? "active" : ""
                }`}
                smooth
                to="#apartemen"
                onClick={() => handleLinkClick("#apartemen")}>
                Office
              </HashLink>
            </li>
            <li>
              <HashLink
                className={`link ${activeLink === "#ruko" ? "active" : ""}`}
                smooth
                to="#lokasi"
                onClick={() => handleLinkClick("#ruko")}>
                Location
              </HashLink>
            </li>
            <br />
          </ul>
        </div>
        <div className="icons" onClick={toggleMenu}>
          <FaBars className={isOpen ? "bars active" : "bars"} />
          <RiCloseLine className={isOpen ? "close active" : "close"} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
