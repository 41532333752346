import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logoeonna from "../../Media/logo_ken.png";

const abouteonna = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo%20Abel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://thekensington-kelapagading.id/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <div className="heading-about">
          <div className="logo">
            <img className="gambareonna" src={logoeonna} alt="logo eonna" />
          </div>
          <div className="judul2">A Seamless Blend of Luxury & Nature</div>
          <div className="desk">
            The Kensington adalah kompleks kantor dan hunian eksklusif di Kelapa
            Gading, Jakarta Utara. The Kensington adalah sebuah kawasan
            "mixed-use development" dengan Apartement, Commercial Area, dan
            Office Area di area 4 hektar. Kensington Royal Suites adalah
            apartemen premium dengan desain arsitektur modern dan memiliki
            fasilitas mewah seperti landscape berkonsep Lifestyle Resort, teras,
            taman podium, kolam renang, pusat kebugaran, dan banyak lagi. Office
            Tower dirancang dengan desain arsitektur modern untuk meningkatkan
            produktivitas kerja. Ada area parkir yang nyaman dan taman yang unik
            untuk ruang terbuka perkantoran modern.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambarabout"></div>
      </div>
    </div>
  );
};

export default abouteonna;
