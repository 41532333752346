import Fasilitas1 from '../../Media/Kensington/Residential/Fasilitas (1).webp'
import Fasilitas2 from '../../Media/Kensington/Residential/Fasilitas (2).webp'
import Fasilitas3 from '../../Media/Kensington/Residential/Fasilitas (3).webp'
import Fasilitas4 from '../../Media/Kensington/Residential/Fasilitas (4).webp'
import Fasilitas5 from '../../Media/Kensington/Residential/Fasilitas (5).webp'



const Fasilitasimages =[
Fasilitas1, Fasilitas2, Fasilitas3, Fasilitas4, Fasilitas5
]

export default Fasilitasimages;