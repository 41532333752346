import Layout1 from '../../Media/Kensington/Office/Layout (1).webp'
import Layout2 from '../../Media/Kensington/Office/Layout (2).webp'
import Layout3 from '../../Media/Kensington/Office/Layout (3).webp'
import Layout4 from '../../Media/Kensington/Office/Layout (4).webp'
import Layout5 from '../../Media/Kensington/Office/Layout (5).webp'
import Layout6 from '../../Media/Kensington/Office/Layout (6).webp'
import Layout7 from '../../Media/Kensington/Office/Layout (7).webp'
import Layout8 from '../../Media/Kensington/Office/Layout (8).webp'
import Layout9 from '../../Media/Kensington/Office/Layout (9).webp'

const Layoutimages =[
Layout1, Layout2, Layout3, Layout4, Layout5, Layout6, Layout7, Layout8, Layout9
]

export default Layoutimages;