import React from "react";
import "./office.scss";
import Officeimages from "./office.js";
import Layoutimages from "./layout.js";
import logoviola from "../../Media/logo_office.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const office = () => {
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Office)+https://marketingsummabekasi.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="office" className="container-office">
      <div className="logo-regia">
        <img src={logoviola} alt="logo-regia" />
      </div>
      <div className="heroregia">
        <div className="slider-regia">
          <Slider {...settings1}>
            {Officeimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
        <div className="desk-regia">
          The Kensington Office Tower adalah sebuah bangunan perkantoran modern
          yang terletak di Kelapa Gading, Jakarta Utara.Dirancang dengan desain
          arsitektur modern, The Kensington Office Tower menawarkan gaya hidup
          dinamis dan kemudahan akses ke kawasan bisnis di Kelapa Gading.
          Menyediakan ruang terbuka perkantoran modern yang luas berupa taman
          dengan konsep unik dan menyegarkan. Fasilitas lainnya termasuk area
          parkir yang luas (298 tempat parkir), foodcourt, dan berbagai
          fasilitas lain seperti pusat kebugaran, kolam renang, dan ruang
          pertemuan.
        </div>
      </div>
      <div className="container-layout">
        <div className="judul-office">
          <h1>Floor Plan</h1>
          <h2>The Kensington</h2>
          <h3>Office Tower</h3>
        </div>
        <div className="slider-layout">
          <Slider {...settings2}>
            {Layoutimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Whatsapp
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default office;
