import Residential1 from '../../Media/Kensington/Residential/Residential (1).webp'
import Residential2 from '../../Media/Kensington/Residential/Residential (2).webp'
import Residential3 from '../../Media/Kensington/Residential/Residential (3).webp'
import Residential4 from '../../Media/Kensington/Residential/Residential (4).webp'
import Residential5 from '../../Media/Kensington/Residential/Residential (5).webp'
import Residential6 from '../../Media/Kensington/Residential/Residential (6).webp'


const Residentialimages =[
Residential1, Residential2, Residential3, Residential4, Residential5, Residential6
]

export default Residentialimages;