import React from "react";
import "./residential.scss";
import logoviola from "../../Media/logo_residential.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Residentialimages from "./residential.js";
import Fasilitasimages from "./fasilitas.js";
import Layoutimages from "./layout.js";
import layout_RB1a from "../../Media/Kensington/Residential/Layout/RB1a.webp";
import layout_RB1a1 from "../../Media/Kensington/Residential/Layout/RB1a1.webp";
import layout_RBb from "../../Media/Kensington/Residential/Layout/RBb.webp";
import layout_RC1a from "../../Media/Kensington/Residential/Layout/RC1a.webp";
import layout_RCa from "../../Media/Kensington/Residential/Layout/RCa.webp";
import layout_RCb from "../../Media/Kensington/Residential/Layout/RCb.webp";
import layout_SAa from "../../Media/Kensington/Residential/Layout/SAa.webp";
import layout_SBa from "../../Media/Kensington/Residential/Layout/SBa.webp";
import layout_SBb from "../../Media/Kensington/Residential/Layout/SBb.webp";
import layout_SCa from "../../Media/Kensington/Residential/Layout/SCa.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faMedal} from "@fortawesome/free-solid-svg-icons";

const rumah = () => {
  const RB1awa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RB1a)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const RB1a1wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RB1a)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const RBbwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RBb)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const RC1awa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RC1a)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const RCawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RCa)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const RCbwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20RCb)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  const SAawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20SAa)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const SBawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20SBa)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const SBbwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20SBb)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const SCawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo+Abel,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20The%20Kensington%20(Type%20Unit%20SCa)+https://thekensington-kelapagading.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const items = [
    "Clubhouse",
    "Swimming Pool",
    "Children Playground",
    "Multifunction Room",
    "Communal Park",
    "Face Recognition System Gate",
    "Security 24 Hours",
  ];
  return (
    <div id="apartemen" className="rumah">
      <div className="logo-viola">
        <img src={logoviola} alt="logo-viola" />
      </div>
      <div className="heroviola">
        <div className="container-slider">
          <div className="slider-viola">
            <Slider {...settings1}>
              {Residentialimages.map((image, index) => (
                <img className="imagecard" key={index} src={image} />
              ))}
            </Slider>
          </div>
        </div>
        <div className="container-kensington">
          <div className="desk-viola">
            The Kensington Royal Suites adalah sebuah kompleks apartemen premium
            yang terletak di kawasan Summarecon Kelapa Gading, Jakarta Utara.
            The Kensington Royal Suites dirancang sebagai apartemen premium
            dengan konsep Lifestyle Resort, menciptakan harmonisasi antara
            arsitektur modern kontemporer dan lanskap hijau yang luas. Kompleks
            ini memiliki area roof garden yang cukup luas dan planter box yang
            banyak, serta fasilitas-fasilitas mewah seperti private lift, lobby
            lift, swimming pool, kids pool, fitness center, dan lain-lain.
          </div>
        </div>
      </div>
      <div className="container-layout">
        <div className="container-judul">
          <h1>Floor Plan Royal</h1>
          <h2>Private Lift</h2>
        </div>
        <div className="container-card">
          <div className="container-card1">
            <div className="card">
              <img src={layout_RB1a} alt="layout_RB1a" />
              <div className="judul">Type Unit RB1a</div>
              <button className="buttonpenawaran" onClick={RB1awa}>
                Whatsapp
              </button>
            </div>
            <div className="card">
              <img src={layout_RB1a1} alt="layout_RB1a" />
              <div className="judul">Type Unit RB1a1</div>
              <button className="buttonpenawaran" onClick={RB1a1wa}>
                Whatsapp
              </button>
            </div>
            <div className="card">
              <img src={layout_RBb} alt="layout_RB1a" />
              <div className="judul">Type Unit RBb</div>
              <button className="buttonpenawaran" onClick={RBbwa}>
                Whatsapp
              </button>
            </div>
          </div>
          <div className="container-card2">
            <div className="card">
              <img src={layout_RC1a} alt="layout_RB1a" />
              <div className="judul">Type Unit RC1a</div>
              <button className="buttonpenawaran" onClick={RC1awa}>
                Whatsapp
              </button>
            </div>
            <div className="card">
              <img src={layout_RCa} alt="layout_RB1a" />
              <div className="judul">Type Unit RCa</div>
              <button className="buttonpenawaran" onClick={RCawa}>
                Whatsapp
              </button>
            </div>
            <div className="card">
              <img src={layout_RCb} alt="layout_RB1a" />
              <div className="judul">Type Unit RCb</div>
              <button className="buttonpenawaran" onClick={RCbwa}>
                Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="layoutsuites">
        <div className="judul-suites">
          <h1>Floor Plan Suites</h1>
          <h2>Sharing Lift</h2>
        </div>
        <div className="container-suites">
          <div className="cardsuites">
            <img src={layout_SAa} alt="layout_RB1a" />
            <div className="judulsuites">Type Unit SAa</div>
            <button className="buttonpenawaran" onClick={SAawa}>
              Whatsapp
            </button>
          </div>
          <div className="cardsuites">
            <img src={layout_SBa} alt="layout_RB1a" />
            <div className="judulsuites">Type Unit SBa</div>
            <button className="buttonpenawaran" onClick={SBawa}>
              Whatsapp
            </button>
          </div>
          <div className="cardsuites">
            <img src={layout_SBb} alt="layout_RB1a" />
            <div className="judulsuites">Type Unit SBb</div>
            <button className="buttonpenawaran" onClick={SBbwa}>
              Whatsapp
            </button>
          </div>
          <div className="cardsuites">
            <img src={layout_SCa} alt="layout_RB1a" />
            <div className="judulsuites">Type Unit SCa</div>
            <button className="buttonpenawaran" onClick={SCawa}>
              Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="fasilitas-kensington">
        <div className="judul-fasilitas">RESIDENTIAL FACILITIES</div>
        <div className="slider-fasilitas">
          <Slider {...settings2}>
            {Fasilitasimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
        <ul className="fasilitas-list">
          {items.map((item, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faCheckDouble} color="white" />
              <span> &nbsp; {item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default rumah;
