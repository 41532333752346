import React from "react";
import "./home.scss";
import Navbar from "../../Section/Header/navbar.jsx";
import Banner from "../../Section/Banner/banner.jsx";
import About from "../../Section/About/about.jsx";
import Promo from "../../Section/Promo/promo.jsx";
import Produk from "../../Section/Produk/produk.jsx";
import Residential from "../../Section/Residential/residential.jsx";
import Office from "../../Section/Office/office.jsx";
import Lokasi from "../../Section/Lokasi/lokasi.jsx";
import Footer from "../../Section/Footer/footer.jsx";
import tombol from "../../Media/tombol.webp";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281281841001&text=Halo%20Abel,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Kensington%20https://thekensington-kelapagading.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Produk />
      <Residential />
      <Office />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
